import { useCallback } from "react";
import { useAnalyticsUtilities } from "../hooks/useAnalyticsUtilities";
import META from "./useTrackJoinedLibrary.meta";
export const useTrackJoinedLibrary = ()=>{
    const { track } = useAnalyticsUtilities();
    const trackJoinedLibrary = useCallback((params)=>{
        track(META.name, params);
    }, [
        track
    ]);
    return {
        trackJoinedLibrary
    };
};
