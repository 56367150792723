import { useCallback } from "react";
import { useAnalyticsUtilities } from "../hooks/useAnalyticsUtilities";
import META from "./useTrackJoinedLibrary.meta";
export const useTrackRequestedLibraryAccess = ()=>{
    const { track } = useAnalyticsUtilities();
    const trackRequestedLibraryAccess = useCallback((params)=>{
        track(META.name, params);
    }, [
        track
    ]);
    return {
        trackRequestedLibraryAccess
    };
};
