import { type Board } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackRestoreBoard.meta';

export type UseTrackRestoreBoardParams = {
  board_id: Board['id'];
  board_name: Board['title'];
};

export const useTrackRestoreBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackRestoreBoard = useCallback(
    (params: UseTrackRestoreBoardParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackRestoreBoard };
};
