// polyfills
import 'intersection-observer';
import 'focus-visible';
import 'resize-observer-polyfill';
// scripts
import '~/utils/whyDidYouRender';
import '~/utils/apiDebugMode';
import '@reach/combobox/styles.css';
import 'normalize.css';
import '~/styles/globals.scss';
import '~/styles/search-suggestions.css';
import '~/styles/table-page-container.css';
import '~/styles/stripe-styles.scss';
import '~/styles/import-file-list.scss';

import { AnalyticsPageRouting, AnalyticsProvider } from '@air/analytics';
import { NetworkStatusInfo } from '@air/classes-network-status-info';
import { TooltipProvider } from '@air/primitive-tooltip';
import { MediaQueryProvider } from '@air/provider-media-query';
import { ToastProvider } from '@air/provider-toast';
import { Globals, useReducedMotion } from '@react-spring/web';
import { QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { ThemeProvider as NextThemeProvider } from 'next-themes';
import { type ReactElement, type ReactNode, useEffect, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HotkeysProvider } from 'react-hotkeys-hook';

import { getAnalyticsAreDisabled } from '~/analytics/utils';
import { OutdatedBrowserBanner } from '~/components/Banners/OutdatedBrowserBanner';
import { LogRocketContainer } from '~/components/LogRocket/LogRocketContainer';
import ReactScanMonitoring from '~/components/ReactScanMonitoring';
import { ReloadApplication } from '~/components/ReloadApplication/ReloadApplication';
import { UpdateAppHeightVariableListener } from '~/components/UpdateAppHeightVariableListener';
import { I18n } from '~/constants/I18n';
import { THEME } from '~/constants/localStorageKeys';
import { useOpenIntercomIfQueryParamExists } from '~/hooks/useOpenIntercomIfQueryParamExists';
import { usePersistReferral } from '~/hooks/usePersistReferral';
import { LaunchDarklyProvider } from '~/providers/LaunchDarklyProvider';
import { ReloadApplicationProvider } from '~/providers/ReloadApplicationProvider';
import { queryClient } from '~/swr-hooks/queryClient';
import { configureAPIPackage } from '~/utils/ConfigureAmplify';
import { isCypressRun } from '~/utils/PathUtils';
import { getPageUrl } from '~/utils/Url';

configureAPIPackage();

NetworkStatusInfo.endpoint = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/health`;

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & {
    getLayout?: (page: JSX.Element) => JSX.Element;
  };
};

export default function App({ Component, pageProps, router }: AppPropsWithLayout) {
  const reducedMotion = useReducedMotion();
  const pageUrl = getPageUrl();
  const canonicalURL = `${pageUrl}${router.asPath}`;

  const { openIntercomIfQueryParamExists } = useOpenIntercomIfQueryParamExists();

  // Uncomment this to see react-scan widget on the page
  // useEffect(() => {
  //   // Make sure to run React Scan after hydration
  //   scan({
  //     enabled: true,
  //   });
  // }, []);

  /**
   * This disables animations in react-spring when the user has reduced motion
   * enabled in their OS settings.
   * @see https://react-spring.dev/docs/utilities/use-reduced-motion
   */
  useEffect(() => {
    if (reducedMotion) {
      Globals.assign({
        skipAnimation: reducedMotion,
      });
    }
    return () =>
      Globals.assign({
        skipAnimation: false,
      });
  }, [reducedMotion]);

  /** Persistent layouts - https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/ */
  const getLayout = Component.getLayout;

  const MemoizedLayout = useMemo(() => {
    return getLayout ? getLayout(<Component {...pageProps} />) : <Component {...pageProps} />;
  }, [getLayout, Component, pageProps]);

  // Referral code persistence
  usePersistReferral();

  const launchDarklyKey = process.env.NEXT_PUBLIC_LAUNCH_DARKLY_KEY;

  if (!launchDarklyKey) {
    console.error('No LaunchDarkly key was provided! Missing the NEXT_PUBLIC_LAUNCH_DARKLY_KEY environment variable.');
  }

  const children = (
    <>
      <DefaultSeo
        titleTemplate="%s / Air"
        description={I18n.basePageDescription}
        canonical={canonicalURL}
        openGraph={{
          type: 'website',
          description: I18n.basePageDescription,
          url: canonicalURL,
          title: I18n.basePageTitle,
          site_name: 'Air',
          images: [
            {
              url: `${pageUrl}/assets/air-og-image.png`,
              width: 1024,
              height: 512,
              alt: I18n.basePageCardAlt,
            },
          ],
        }}
        twitter={{
          handle: '@airHQ',
          cardType: 'summary_large_image',
        }}
      />

      <ReactScanMonitoring />

      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      </Head>

      <UpdateAppHeightVariableListener />
      <NextThemeProvider defaultTheme="light" storageKey={THEME} themes={['dark', 'light']}>
        <TooltipProvider>
          <HotkeysProvider>
            <MediaQueryProvider>
              <AnalyticsProvider
                analyticsKey={process.env.NEXT_PUBLIC_SEGMENT_KEY!}
                isDisabled={getAnalyticsAreDisabled()}
              >
                <QueryClientProvider client={queryClient}>
                  <LaunchDarklyProvider>
                    <ReloadApplicationProvider>
                      <LogRocketContainer>
                        <ToastProvider>
                          <AnalyticsPageRouting onFirstLoad={openIntercomIfQueryParamExists} />
                          <OutdatedBrowserBanner />
                          <ReloadApplication />
                          <DndProvider backend={HTML5Backend}>{MemoizedLayout}</DndProvider>
                          {/*<ReactQueryDevtools initialIsOpen={false} />*/}
                        </ToastProvider>
                      </LogRocketContainer>
                    </ReloadApplicationProvider>
                  </LaunchDarklyProvider>
                </QueryClientProvider>
              </AnalyticsProvider>
            </MediaQueryProvider>
          </HotkeysProvider>
        </TooltipProvider>
      </NextThemeProvider>
    </>
  );

  return launchDarklyKey ? (
    <LDProvider
      clientSideID={launchDarklyKey}
      options={{
        evaluationReasons: !isCypressRun(),
        fetchGoals: !isCypressRun(),
        sendEvents: !isCypressRun(),
        streaming: !isCypressRun(),
      }}
    >
      {children}
    </LDProvider>
  ) : (
    children
  );
}
