import { object } from "yup";
import { emailValidationSchema } from "../schemas/emailValidationSchema";
import { firstNameValidationSchema } from "../schemas/firstNameValidationSchema";
import { lastNameValidationSchema } from "../schemas/lastNameValidationSchema";
import { passwordValidationSchema } from "../schemas/passwordValidationSchema";
import { termsValidationSchema } from "../schemas/termsValidationSchema";
export const getRegistrationValidationSchema = (email)=>{
    return object().shape({
        firstName: firstNameValidationSchema.default(""),
        lastName: lastNameValidationSchema.default(""),
        email: emailValidationSchema.default(email ? decodeURIComponent(email) : ""),
        password: passwordValidationSchema.default(""),
        terms: termsValidationSchema.default(false)
    }).required();
};
