import { type Library } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackRestoreLibrary.meta';

export type UseTrackRestoreLibraryParams = {
  library_id: Library['id'];
  library_name: Library['title'];
};

export const useTrackRestoreLibrary = () => {
  const { track } = useAnalyticsUtilities();

  const trackRestoreLibrary = useCallback(
    (params: UseTrackRestoreLibraryParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackRestoreLibrary };
};
