import { useEffect } from "react";
/**
 * On mobile web, `100vh` is not always the full height of the screen due to the browser UI not being taken into account. As a result, we need to set the `--app-height` CSS variable to the full height of the document to ensure that the app takes up the full height of the screen.
 */ export const UpdateAppHeightVariableListener = ()=>{
    useEffect(()=>{
        const updateAppHeight = ()=>{
            document.documentElement.style.setProperty("--app-height", "".concat(window.innerHeight, "px"));
        };
        window.addEventListener("resize", updateAppHeight);
        /**
     * This is a fix for iOS Safari where the `resize` event is not fired when the orientation changes.
     * Instead, we listen for the `orientationchange` event and update the app height accordingly.
     */ window.addEventListener("orientationchange", updateAppHeight);
        updateAppHeight();
        return ()=>{
            window.removeEventListener("resize", updateAppHeight);
            window.removeEventListener("orientationchange", updateAppHeight);
        };
    }, []);
    return null;
};
