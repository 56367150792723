import { Meta } from '../types/docs';

const META: Meta = {
  name: 'recently_deleted_sort_applied',
  description: 'Tracks the recently deleted date sort being applied',
  params: [
    {
      name: 'sort_type',
      description: 'The sort type for recently deleted',
      required: true,
      type: 'string',
    },
    {
      name: 'sort_direction',
      description: 'The sort direction for recently deleted',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
