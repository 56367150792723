import { Meta } from '../types/docs';

const META: Meta = {
  name: 'restore_board',
  description: 'Tracks the restoration of an board',
  params: [
    {
      name: 'board_id',
      description: 'The ID of the restored board',
      required: true,
      type: 'string',
    },
    {
      name: 'board_name',
      description: 'The name of the restored board',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
