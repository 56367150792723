import { useCallback } from "react";
import { useAnalyticsUtilities } from "../hooks/useAnalyticsUtilities";
import META from "./useTrackCreatedLibrary.meta";
export const useTrackCreatedLibrary = ()=>{
    const { track } = useAnalyticsUtilities();
    const trackCreatedLibrary = useCallback((params)=>{
        track(META.name, params);
    }, [
        track
    ]);
    return {
        trackCreatedLibrary
    };
};
