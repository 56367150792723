import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackClickedSidebarNavigationItem.meta';

export type UseTrackClickedSidebarNavigationItemParams = {
  item_name: string;
};

export const useTrackClickedSidebarNavigationItem = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedSidebarNavigationItem = useCallback(
    (params: UseTrackClickedSidebarNavigationItemParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackClickedSidebarNavigationItem };
};
