import { Meta } from '../types/docs';

const META: Meta = {
  name: 'restore_asset',
  description: 'Tracks the restoration of an asset',
  params: [
    {
      name: 'asset_id',
      description: 'The ID of the restored asset',
      required: true,
      type: 'string',
    },
    {
      name: 'asset_name',
      description: 'The name of the restored asset',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
