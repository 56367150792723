import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackClickedRecentlyDeletedUpgradePrompt.meta';

export const useTrackClickedRecentlyDeletedUpgradePrompt = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedRecentlyDeletedUpgradePrompt = useCallback(() => {
    track(META.name);
  }, [track]);

  return { trackClickedRecentlyDeletedUpgradePrompt };
};
