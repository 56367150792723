import { SortDirection } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackRecentlyDeletedSortApplied.meta';

export type UseTrackRecentlyDeletedSortAppliedParams = {
  sort_type: 'date_deleted';
  sort_direction: SortDirection;
};

export const useTrackRecentlyDeletedSortApplied = () => {
  const { track } = useAnalyticsUtilities();

  const trackRecentlyDeletedSortApplied = useCallback(
    (params: UseTrackRecentlyDeletedSortAppliedParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackRecentlyDeletedSortApplied };
};
