import { useTrackOpenIntercomChat } from '@air/analytics';
import { useCallback, useRef } from 'react';

import { QueryParamNames } from '~/constants/search';
import { pushWithExistingQuery } from '~/utils/PathUtils';

/**
 * This hook opens intercom if openChat=true param exists in the URL and then removes the param
 */
export const useOpenIntercomIfQueryParamExists = () => {
  const { trackOpenIntercomChat } = useTrackOpenIntercomChat();

  const wasChatOpened = useRef(false);

  const openIntercomIfQueryParamExists = useCallback(() => {
    if (!wasChatOpened.current) {
      wasChatOpened.current = true;
      const params = new URLSearchParams(window.location.search);
      if (params.get(QueryParamNames.openChat) === 'true') {
        pushWithExistingQuery({ newQuery: { openChat: undefined }, method: 'replace' });
        setTimeout(() => trackOpenIntercomChat(), 2000);
      }
    }
  }, [trackOpenIntercomChat]);

  return { openIntercomIfQueryParamExists };
};
