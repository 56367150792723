import { Meta } from '../types/docs';

const META: Meta = {
  name: 'clicked_sidebar_navigation_item',
  description: 'Tracks the click of a sidebar navigation item',
  params: [
    {
      name: 'sidebar_navigation_item_name',
      description: 'The ID of the API key',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
