import { AIR_API_CONFIG } from '../constants/airApiConfig';
import { ANONYMOUS_COGNITO_CONFIG } from '../constants/cognitoConfig';
import { ANONYMOUS_OAUTH_CONFIG } from '../constants/oauthConfig';
import { DeepPartial } from '../types/DeepPartial';
import { configureAPIPackage, ConfigureAPIPackageParams } from './configureAPIPackage';

export const configureAPIPackageForAnonymousSignUp = (params?: DeepPartial<ConfigureAPIPackageParams>) =>
  configureAPIPackage({
    cognitoConfigParams: ANONYMOUS_COGNITO_CONFIG,
    oauthConfigParams: ANONYMOUS_OAUTH_CONFIG,
    baseUrl: AIR_API_CONFIG.baseUrl,
    ...params,
  });
