import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import { ANALYTICS_DEBUG_KEY, logger } from '../utils/logger';

export const useAnalyticsDebug = () => {
  const [value, setValue, remove] = useLocalStorage(ANALYTICS_DEBUG_KEY, false);

  const onClear = useCallback(() => {
    logger('Clearing analytics debug');

    remove();
  }, [remove]);

  const onToggle = useCallback(() => {
    if (!value) {
      logger(`Enabling analytics debugger`);
    } else {
      logger(`Disabling analytics debugger`);
    }

    setValue(!value);
  }, [setValue, value]);

  return { onClear, onToggle, value };
};
