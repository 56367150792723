import { type Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackRestoreAsset.meta';

export type UseTrackRestoreAssetParams = {
  asset_id: Clip['id'];
  asset_name: Clip['title'];
};

export const useTrackRestoreAsset = () => {
  const { track } = useAnalyticsUtilities();

  const trackRestoreAsset = useCallback(
    (params: UseTrackRestoreAssetParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackRestoreAsset };
};
