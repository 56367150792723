import { QueryKey } from '@tanstack/react-query';

import { isCypressRun } from '~/utils/PathUtils';

export const getFetchParamsFromKey = (key: string) => {
  const queryParams = new URLSearchParams(key.split('?')[1]);
  const cursor = queryParams.get('cursor');
  return {
    cursor,
  };
};

export const isLoadingMore = (size: number, data?: any[]) => {
  return !data || (size > 0 && data && !data[size - 1]);
};

export const isDevStage = () => {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' || process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
};

export const isDevOrTestStage = () => isDevStage() || isCypressRun();

export type ReactQueryPredicate = ({ queryKey }: { queryKey: QueryKey }) => boolean;
