import { useCallback } from "react";
import { useAnalyticsUtilities } from "../hooks/useAnalyticsUtilities";
import META from "./useTrackViewedRecentlyDeletedPage.meta";
export const useTrackViewedRecentlyDeletedPage = ()=>{
    const { track } = useAnalyticsUtilities();
    const trackViewedRecentlyDeletedPage = useCallback(()=>{
        track(META.name);
    }, [
        track
    ]);
    return {
        trackViewedRecentlyDeletedPage
    };
};
