import { useCallback } from "react";
import { useAnalyticsUtilities } from "../hooks/useAnalyticsUtilities";
import META from "./useTrackDeletedItems.meta";
export const useTrackDeletedItems = ()=>{
    const { track } = useAnalyticsUtilities();
    const trackDeletedItems = useCallback((params)=>{
        track(META.name, params);
    }, [
        track
    ]);
    return {
        trackDeletedItems
    };
};
