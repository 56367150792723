import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

type ImportOptions =
  | 'import-from-drive'
  | 'import-from-box'
  | 'import-from-dropbox'
  | 'import-from-brandfolder'
  | 'import-from-sharePoint';

export type TrackClickedImport = {
  location: AirActionTrackingLocation;
  origin: ImportOptions;
};

export const useTrackClickedImport = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedImport = useCallback(
    (params: TrackClickedImport) => {
      track(`Clicked Import`, params);
    },
    [track],
  );

  return { trackClickedImport };
};
